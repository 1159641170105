import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class FolletosService {

  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });
​
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  async getBenefSistema() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Beneficios_sistema']}`;
    return url;
  }

  async getOtrosBenef() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Otros_Beneficios']}`;
    return url;
  }

  async getAfiliacion() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Afiliacion']}`;
    return url;
  }

  async getCotizaciones() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Cotizaciones']}`;
    return url;
  }

  async getCuentaCapiIndiv() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Cuenta_Capitalizacion']}`;
    return url;
  }

  async getCotiVoluntaria() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Cotizaciones_Voluntarias']}`;
    return url;
  }

  async getAhorroPrevVol() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Ahorro_Previsional']}`;
    return url;
  }

  async getCuentaAhorroVol() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Cuenta_Ahorro_Vol']}`;
    return url;
  }

  async getCAhorroIndemnizacion() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Cuenta_Indemnizacion']}`;
    return url;
  }

  async getDepositosConv() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Depositos_Convenidos']}`;
    return url;
  }

  async getComisiones() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Comisiones']}`;
    return url;
  }

  async getTraspasoCCPersonales() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Traspaso_CP']}`;
    return url;
  }

  async getSitioWebAfp() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Sitio_Web_Afp']}`;
    return url;
  }

  async getEstadoCCapitaliIndv() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Estado_CCapitalizacion']}`;
    return url;
  }

  async getSistConsulMontosPension() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Sistema_Consultas_Ofertas']}`;
    return url;
  }

  async getBonoReconocimiento() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Bono_Reconocimiento']}`;
    return url;
  }

  async getDesafiliaciones() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Desafiliaciones']}`;
    return url;
  }

  async getDevolPagosExcesos() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Pagos_Excesos']}`;
    return url;
  }

  async getReclamos() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Sistema_Previsional']['Reclamos']}`;
    return url;
  }

  async getPensionVejez() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Pensiones']['Pension_Vejez']}`;
    return url;
  }

  async getPensionInvalidez() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Pensiones']['Pension_Invalidez']}`;
    return url;
  }

  async getPensionSobrevivencia() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Pensiones']['Pension_Sobrevivencia']}`;
    return url;
  }

  async getPensionInvSobrevi() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Pensiones']['Pension_Invalidez_Sobrevivencia']}`;
    return url;
  }

  async getFondoPensiones() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Fondos']['Fondos_Pensiones']}`;
    return url;
  }

  async getFondoPensionesABCD() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Fondos']['Fondos_Pensiones_ABCD']}`;
    return url;
  }

  async getCambioDistriFondos() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Fondos']['Cambio_Distribucion_Fondos']}`;
    return url;
  }

  async getAporteSolidario() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Aporte_Solidario']}`;
    return url;
  }

  async getPensionBasicaSol() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Pension_Basica_Solidaria']}`;
    return url;
  }

  async getAfiliadoVol() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Afiliado_Voluntario']}`;
    return url;
  }

  async getAfiliadoIndependiente() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Afiliado_Independiente']}`;
    return url;
  }

  async getCuentaAhorroPrevi() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Cuenta_Ahorro_Previsional']}`;
    return url;
  }

  async getCompDivorcio() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Compensacion_Divorcio']}`;
    return url;
  }

  async getBonifHijo() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Bonificacion_Hijo']}`;
    return url;
  }

  async getSubsidioTrabajadorJoven() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Subsidio_Trabajador_Joven']}`;
    return url;
  }

  async getAsesorPrevi() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['Folletos']['Reformas_Previsional']['Asesor_Previsional']}`;
    return url;
  }
}
