import { Injectable } from '@angular/core';
import { ConfigService } from '../config/config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private headers: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private httpClient: HttpClient,
    private userIdle: UserIdleService,
    private configService: ConfigService,
    private router: Router
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  async generarClave(rut: string, claveNueva: string, claveNuevaConfirmar: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/Auth/GenerarClaveWeb`;
    try {
      return await this.httpClient.post(url, { rut, claveNueva, claveNuevaConfirmar }, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async getUserInfo(rut: string) {
    const endpoint = await this.configService.getConfig();
    const params = `?rut=${rut}`;
    const url = `${endpoint['endpoint']}/Auth/GetUserInfo${params}`;
    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async validarLogin(rut: any, dv: any) {
    const endpoint = await this.configService.getConfig();
    const params = `?rut=${rut}&dv=${dv}`;
    const url = `${endpoint['autentia']}/Auth/ObtenerValidacion${params}`;
    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  logout(): void {
    localStorage.clear();
    //QA
    // window.location.href = `${window.location.origin}/AFPModelo/front/`;
    //prod
    window.location.href = `${window.location.origin}/`;
  }

  idleUser() {
    this.userIdle.startWatching();

    this.subscriptions.push(this.userIdle.onTimerStart().subscribe(count => {
      const eventList = [
        'click', 'mouseover', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'keyup'
      ];
      eventList.map((event => {
        document.body.addEventListener(event, () => this.userIdle.resetTimer());
      }));
    }));

    this.subscriptions.push(this.userIdle.onTimeout().subscribe(() => {
      if (this.router.url !== '/') {
        this.userIdle.resetTimer();
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.logout();
      }
    }));
  }
}
