import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class CertificadosService {

  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });
​
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  async emitirCertRemuneraciones(rut: string, perIni: string, perFin: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/CertRemuneraciones/EmitirCertRemuneraciones`;

    try {
      return await this.httpClient.post(url, { rut, perIni, perFin }, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async getTiposProductos(rut: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/CertCotizaciones/ObtenerTiposProductosVig?rut=${rut}`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async emitirCertCotizaciones(rut: string, numPeriodos: string, tipoProducto: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/CertCotizaciones/EmitirCertCotizaciones`;

    try {
      return await this.httpClient.post(url, { rut, numPeriodos, tipoProducto }, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async emitirCartolaCuatri(rut: any, periodos: number) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/OtrosCert/CartolaCuatrimestral?rut=${ rut }`;

    const data = {
      rut,
      periodos
    };

    try {
      return await this.httpClient.post(url, data, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }
}
