import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  viewCertif = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.validaMenu();
    localStorage.setItem('back', '');
  }

  validaMenu() {
    const back = localStorage.getItem('back');
    if (back === 'auth') {
      this.viewCertif = true;
    } else {
      this.viewCertif = false;
    }
  }

  menu(e: any, action: string) {
    e.preventDefault();
    if (action === 'certificados') {
      this.viewCertif = true;
    } else {
      this.router.navigate([action]);
    }
  }

  change(e: any, action: string) {
    e.preventDefault();
    if (action === 'volver') {
      this.viewCertif = false;
    } else {
      this.router.navigate([action]);
    }
  }

}
