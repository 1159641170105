import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

// Services
import { ModalTecladoService } from 'src/app/core/services/modals/modal-teclado.service';
import { EmitTecladoService } from 'src/app/core/services/emit-data/emit-teclado.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { CertificadosService } from 'src/app/core/services/http/certificados.service';
import { FolletosService } from 'src/app/core/services/http/folletos.service';
import { OtrosCertificadosService } from 'src/app/core/services/http/otros-certificados.service';
import { PrintService } from 'src/app/core/services/http/print.service';
import { ReporteriaService } from '../../../core/services/http/reporteria/reporteria.service';

// Utilidades
import * as moment from 'moment';
import { ConfigService } from 'src/app/core/services/config/config.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
})
export class SendEmailComponent implements OnInit, OnDestroy {
  @Output() changeStep = new EventEmitter<any>();
  @Input() certificado: string;
  @Input() option: any;
  @Input() meses: number;
  @Input() toprint: string;
  @Input() email: string;
  @Input() source: string;
  view = 'email';
  action = '';
  emailForm: FormGroup;
  submitted = false;
  docUrl: string;
  base64: any;
  subject = '';
  response;
  buttonPrint = true;

  emailed = false;
  printed = false;
  type = '';

  showView = false;
  demoraTimeout!:any;
  showBtnCancelar = false;
  printSuscription!:Subscription;
  cancelPrintSuscription!:Subscription;

  //Estado de impresión
  printState: Subject<any> = new Subject();
  printStateObservable: Observable<any> = this.printState.asObservable();
  printStateSubscription!: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private modalsTecladoService: ModalTecladoService,
    private emitTecladoService: EmitTecladoService,
    private sweetAlertService: SweetAlertService,
    private certificadosService: CertificadosService,
    private otrosCertificadosService: OtrosCertificadosService,
    private folletosService: FolletosService,
    private printService: PrintService,
    private reporteriaService: ReporteriaService,
    private configService: ConfigService
  ) {}

  async ngOnInit() {
    this.validateForm();
    this.showView = this.source ? true : false;
    await this.certificados();
    if(this.toprint == 'print' && this.showView){
      this.onlyPrint();
    }
    this.cancelPrintSuscription= this.sweetAlertService.cancelPrint$.subscribe((cancelar:boolean)=>{
      if(cancelar){
        this.printSuscription.unsubscribe();
        this.sweetAlertService.swalClickClose();
        this.volver('opc-entrega');
      }
    })
  }

  ngOnDestroy() {
    this.sweetAlertService.swalClose();
    this.cancelPrintSuscription.unsubscribe();
    if(this.printSuscription){
      this.printSuscription.unsubscribe();
    }
    if(this.printStateSubscription){
      this.printStateSubscription.unsubscribe();
    }
  }

  get f() {
    return this.emailForm.controls;
  }
  get isEmailMismatch() {
    return this.emailForm.getError('emailMismatch');
  }

  validateForm() {
    const email = localStorage.getItem('emailUserModelo') ? localStorage.getItem('emailUserModelo') : '';
    this.emailForm = this.formBuilder.group({
      email: [email, [Validators.required, Validators.pattern(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i)]],
      confirm: [email, [Validators.required, Validators.email]]
    }, {
      validator: (form: FormGroup) => {
        return form.get('email').value !== form.get('confirm').value ? { emailMismatch: true } : null; }
    });
  }

  async onSubmit() {
    this.submitted = true;

    if (this.emailForm.invalid) {
      if (this.isEmailMismatch) {
        this.sweetAlertService.swalErrorEmail('Los correos ingresados no coinciden entre sí');
      } else {
        this.sweetAlertService.swalErrorEmail('Por favor, ingrese un correo válido');
      }
    } else {
      this.onlyEmail();
    }
  }

  onReset() {
    this.submitted = false;
    this.emailForm.controls.email.setValue('');
    this.emailForm.controls.confirm.setValue('');
  }


  async onlyEmail(){
     /* Sólo envía correo*/
    this.email = this.emailForm.value.email;
    this.type = 'send';
    const response = await this.enviarCorreo();

    if (response['status']) {
      localStorage.setItem('emailUserModelo', this.emailForm.value.email);
      this.view = 'confirm';
    } else {
      this.sweetAlertService.swalErrorCustom('Lo sentimos, hubo un error al enviar correo. Intente nuevamente');
    }

  }

  async certificados() {
    const rut = localStorage.getItem('afpRut');
    const rutAfil = localStorage.getItem('afpRutAfil');
    const producto = localStorage.getItem('producto');

    this.sweetAlertService.swalLoadingCustomMessage('Obteniendo certificado, por favor espere...')
    switch (this.certificado) {
      case 'afiliacion':
        this.response = await this.otrosCertificadosService.emitirCertAfiliacion(rutAfil);
        this.subject = 'Certificado Afiliación';
        break;
      case 'remuneracion':
        const perIni = moment()
          .subtract(this.option, 'months')
          .format('YYYYMM');
        const perFin = moment().format('YYYYMM');

        this.response = await this.certificadosService.emitirCertRemuneraciones(rut, perIni, perFin);
        this.subject = 'Certificado de Remuneración';
        break;
      case 'cotizacion':
        const numCotizaciones = this.option;

        this.response = await this.certificadosService.emitirCertCotizaciones(rut, numCotizaciones, producto);
        this.subject = 'Certificado de Cotización';
        break;
      case 'cartola':
        const rutSinGuion = rut.replace('-','');
        const rutAfiliado = parseInt(rutSinGuion.substr(0, rutSinGuion.length - 1));
        const timeoutError = Symbol();
        const config = await this.configService.getConfig()
        try {
          this.response = await this.timeout(this.certificadosService.emitirCartolaCuatri(rutAfiliado, this.option), config['timeoutResponse'], timeoutError);
          // handle result
        }catch (e) {
          if (e === timeoutError) {
            // handle timeout
            this.response ={
              status:false,
              message:'Certificado no disponible'
            }
          }
        }
        this.subject = 'Cartola Cuatrimestral';
        break;
      case 'antecedentes':
        this.response = await this.otrosCertificadosService.emitirAntecedentesPrevi(rut);
        this.subject = 'Antecedentes Previsionales';
        break;
      case 'ulti-mov':
        const tipoFondo = localStorage.getItem('tipoFondo');
        const perDesde = moment()
          .subtract(this.option, 'months')
          .format('YYYYMM');
        const perHasta = moment().format('YYYYMM');

        this.response = await this.otrosCertificadosService.emitirUltMovimientos(rut, producto, tipoFondo, perDesde, perHasta);
        this.subject = 'Últimos Movimientos';
        break;
      case 'vacaciones':
        this.response = await this.otrosCertificadosService.emitirVacacionesProgre(rut);
        this.subject = 'Vacaciones Progresivas';
        break;
      case 'no-coti':
        const desde = moment()
        .subtract(this.option, 'months')
        .format('01-MM-YYYY');
        const hasta = moment().format('01-MM-YYYY');

        this.response = await this.otrosCertificadosService.emitirPeriodoNoCoti(rut, desde, hasta);
        this.subject = 'Periodos No Cotizados';
        break;
      case 'sistPrevisional':
        await this.sistPrevisional();
        break;
      case 'refPrevisional':
        await this.refPrevisional();
        break;
      case 'pensiones':
        await this.pensiones();
        break;
      case 'fondos':
        await this.fondos();
        break;
      default:
        break;
    }
    this.sweetAlertService.swalClose();

    if (this.response !== undefined) {
      if (this.response['status']) {
        if (this.certificado !== 'cartola') {
          this.docUrl = this.response['data']['urlDoc'];
        } else {
          this.base64 = this.response['data'];
        }
        this.showView = true;
      } else {
        const message = this.response.message && this.response.message !== '' ? this.response.message : 'Certificado no disponible, intente nuevamente';
        const alert = await this.sweetAlertService.swalErrorCustom(message);
        if (alert) {
          this.volver('opc-entrega');
        }
      }
    }
  }

  async sistPrevisional() {
    switch (this.option) {
      case 1:
        this.docUrl = await this.folletosService.getBenefSistema();
        this.subject = 'Folleto Informativo - Beneficiarios del Sistema';
        break;
      case 2:
        this.docUrl = await this.folletosService.getOtrosBenef();
        this.subject = 'Folleto Informativo - Otros Beneficios no Financiados por el Sistema';
        break;
      case 3:
        this.docUrl = await this.folletosService.getAfiliacion();
        this.subject = 'Folleto Informativo - Afiliación';
        break;
      case 4:
        this.docUrl = await this.folletosService.getCotizaciones();
        this.subject = 'Folleto Informativo - Cotizaciones';
        break;
      case 5:
        this.docUrl = await this.folletosService.getCuentaCapiIndiv();
        this.subject = 'Folleto Informativo - Cuenta de Capitalización Individual de Cotizaciones Obligatorias';
        break;
      case 6:
        this.docUrl = await this.folletosService.getCotiVoluntaria();
        this.subject = 'Folleto Informativo - Cotizaciones Voluntarias';
        break;
      case 7:
        this.docUrl = await this.folletosService.getAhorroPrevVol();
        this.subject = 'Folleto Informativo - Ahorro Previsional Voluntario';
        break;
      case 8:
        this.docUrl = await this.folletosService.getCuentaAhorroVol();
        this.subject = 'Folleto Informativo - Cuenta de Ahorro Voluntario';
        break;
      case 9:
        this.docUrl = await this.folletosService.getCAhorroIndemnizacion();
        this.subject = 'Folleto Informativo - Cuenta de Ahorro de Indemnización';
        break;
      case 10:
        this.docUrl = await this.folletosService.getDepositosConv();
        this.subject = 'Folleto Informativo - Depósitos Convenidos';
        break;
      case 11:
        this.docUrl = await this.folletosService.getComisiones();
        this.subject = 'Folleto Informativo - Comisiones';
        break;
      case 12:
        this.docUrl = await this.folletosService.getTraspasoCCPersonales();
        this.subject = 'Folleto Informativo - Traspaso de las Cuentas Personales';
        break;
      case 13:
        this.docUrl = await this.folletosService.getSitioWebAfp();
        this.subject = 'Folleto Informativo - Sitio Web de la AFP';
        break;
      case 14:
        this.docUrl = await this.folletosService.getEstadoCCapitaliIndv();
        this.subject = 'Folleto Informativo - Estado de Cuenta de Capitalización Individual';
        break;
      case 15:
        this.docUrl = await this.folletosService.getSistConsulMontosPension();
        this.subject = 'Folleto Informativo - Sistema de Consultas y Montos de Ofertas de Pensión';
        break;
      case 16:
        this.docUrl = await this.folletosService.getBonoReconocimiento();
        this.subject = 'Folleto Informativo - Bono de Reconocimiento';
        break;
      case 17:
        this.docUrl = await this.folletosService.getDesafiliaciones();
        this.subject = 'Folleto Informativo - Desafiliaciones';
        break;
      case 18:
        this.docUrl = await this.folletosService.getDevolPagosExcesos();
        this.subject = 'Folleto Informativo - Devolución de Pagos en Exceso';
        break;
      case 19:
        this.docUrl = await this.folletosService.getReclamos();
        this.subject = 'Folleto Informativo - Reclamos';
        break;
      default:
        break;
    }
  }

  async pensiones() {
    switch (this.option) {
      case 1:
        this.docUrl = await this.folletosService.getPensionVejez();
        this.subject = 'Folleto Informativo - Pensión de Vejez';
        break;
      case 2:
        this.docUrl = await this.folletosService.getPensionInvalidez();
        this.subject = 'Folleto Informativo - Pensión de Invalidez';
        break;
      case 3:
        this.docUrl = await this.folletosService.getPensionSobrevivencia();
        this.subject = 'Folleto Informativo - Pensión de Sobrevivencia';
        break;
      case 4:
        this.docUrl = await this.folletosService.getPensionInvSobrevi();
        this.subject = 'Folleto Informativo - Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744';
        break;
      default:
        break;
    }
  }

  async fondos() {
    switch (this.option) {
      case 1:
        this.docUrl = await this.folletosService.getFondoPensiones();
        this.subject = 'Folleto Informativo - Fondos de Pensiones';
        break;
      case 2:
        this.docUrl = await this.folletosService.getFondoPensionesABCD();
        this.subject = 'Folleto Informativo - Fondos de Pensiones (A, B, C, D)';
        break;
      default:
        break;
    }
  }

  async refPrevisional() {
    switch (this.option) {
      case 1:
        this.docUrl = await this.folletosService.getAporteSolidario();
        this.subject = 'Folleto Informativo - Aporte Previsional Solidario';
        break;
      case 2:
        this.docUrl = await this.folletosService.getPensionBasicaSol();
        this.subject = 'Folleto Informativo - Pensión Básica Solidaria';
        break;
      case 3:
        this.docUrl = await this.folletosService.getAfiliadoVol();
        this.subject = 'Folleto Informativo - Afiliado Voluntario';
        break;
      case 4:
        this.docUrl = await this.folletosService.getAfiliadoIndependiente();
        this.subject = 'Folleto Informativo - Afiliado Independiente';
        break;
      case 5:
        this.docUrl = await this.folletosService.getCuentaAhorroPrevi();
        this.subject = 'Folleto Informativo - Cuenta de Ahorro Previsional Voluntario Colectivo';
        break;
      case 6:
        this.docUrl = await this.folletosService.getCompDivorcio();
        this.subject = 'Folleto Informativo - Compensación Económica en Caso de Nulidad o Divorcio';
        break;
      case 7:
        this.docUrl = await this.folletosService.getBonifHijo();
        this.subject = 'Folleto Informativo - Bonificación por Hijo para la Mujer';
        break;
      case 8:
        this.docUrl = await this.folletosService.getSubsidioTrabajadorJoven();
        this.subject = 'Folleto Informativo - Subsidio a Trabajadores Jovenes';
        break;
      case 9:
        this.docUrl = await this.folletosService.getAsesorPrevi();
        this.subject = 'Folleto Informativo - Asesor Previsional';
        break;
      default:
        break;
    }
  }

  async enviarCorreo() {
    this.emailed = false;
    this.email = this.emailForm.value.email;
    let response;
    let data;

    this.sweetAlertService.swalLoading();

    if (this.certificado !== 'cartola') {
      data = {
        emailTo: this.email,
        subject: this.subject,
        docUrl: [this.docUrl]
      };
      response = await this.printService.sendEmailUrl(data);
    } else {
      data = {
        emailTo: this.email,
        subject: this.subject,
        docB64: this.base64
      };
      response = await this.printService.sendEmailBase64(data);
    }

    this.emailed = response['status'];
    this.usabilidadSend();
    this.sweetAlertService.swalClose();
    return response;
  }

  async print() {
    this.printed = false;
    const config = await this.configService.getConfig();
    this.sweetAlertService.swalPrintSinTimeout();
    const printer = await this.printService.laserStatus();
    this.reporteUsabilidad(
      'Impresión',
      'Estado de impresora',
      printer['status']
        ? 'Impresora conectada'
        : 'Error con la impresora',
      this.email ? this.email : '',
      printer['status']
    );

    if (printer['status']) {
      if (this.certificado !== 'cartola') {
        this.sweetAlertService.swalPrint(config['tiempoDemora']);
        this.printSuscription =  this.printService.printUrl(this.docUrl,config).subscribe((response)=>{
          this.printState.next(response);
          this.printed = response['status'];
          this.usabilidadPrint();
        },
        (error)=>{
          this.printState.next({ status: false, code: error.code, message: error.message });
          this.usabilidadPrint();
        });
      } else {
        this.sweetAlertService.swalPrint(config['tiempoDemora']);
        this.printSuscription =  this.printService.printBase64(this.base64,config).subscribe((response)=>{
          this.printState.next(response);
          this.printed = response['status'];
          this.usabilidadPrint();
        }, (error)=>{
          this.printState.next({ status: false, code: error.code, message: error.message });
          this.usabilidadPrint();
        });
      }
      
    } else {
      setTimeout(() => this.printState.next({ status: false, code: null, message: 'Se detectó un error con la impresora, solicita ayuda técnica.' }), 0);
    }
  }

  async onlyPrint() {
    this.type = 'print';
    this.email = null;

    const response = await this.print();
    this.printStateSubscription = this.printStateObservable.subscribe((response)=>{
      if (response['status']) {
      // this.view = 'confirmPrint';
      this.changeStep.emit('confirmPrint');
      this.sweetAlertService.swalClickClose();
    } else {
      this.sweetAlertService.swalClickClose();
      const texto = response.message ? response.message : 'Lo sentimos, hubo un error al imprimir. Intente nuevamente';
      this.sweetAlertService.swalErrorCustom(texto).then(()=>{
        this.volver('opc-entrega');
      });
    }
    });
  }

  volver(step: any) {
    this.changeStep.emit(step);
  }

  reporteUsabilidad(modulo: string, recurso: string, respuesta: string, correo: string, status: boolean) {
    const rut = localStorage.getItem('afpRut') ? localStorage.getItem('afpRut') : localStorage.getItem('afpRutAfil');
    
    this.reporteriaService.saveLog(
      modulo,
      recurso,
      respuesta,
      'Front-End',
      correo,
      status,
      rut
    );
  }

  usabilidadSend() {
    switch (this.certificado) {
      // Certificados AFP
      case 'afiliacion':
      this.reporteUsabilidad(
        `Certificado Afiliación AFP`,
        '',
        this.emailed
          ? `Envío de Certificado de Afiliación AFP a correo electrónico`
          : `Error en envío de Certificado de Afiliación AFP a correo electrónico`,
        this.email,
        this.emailed
      );
      break;

      // Otros
      case 'cotizacion':
      this.reporteUsabilidad(
        'Otros',
        `Certificado de Cotización de los últimos ${this.option} meses`,
        this.emailed
          ? `Envío de Certificado de Cotización de los últimos ${this.option} meses a correo electrónico`
          : `Error en envío de Certificado de Cotización de los últimos ${this.option} meses a correo electrónico`,
        this.email,
        this.emailed
      );
      break;
      case 'cartola':
      let periodo: string;
      switch (this.option) {
        case 1:
          periodo = 'última cartola cuatrimestral';
          break;

        case 2:
          periodo = '2 últimas cartolas cuatrimestrales';
          break;

        case 3:
          periodo = '3 últimas cartolas cuatrimestrales';
          break;

        case 4:
          periodo = '4 últimas cartolas cuatrimestrales';
          break;
      }

      this.reporteUsabilidad(
        'Otros',
        `Cartola Cuatrimestral`,
        this.emailed
          ? `Envío de ${ periodo } a correo electrónico`
          : `Error en envío de ${ periodo } a correo electrónico`,
        this.email,
        this.emailed
      );
      break;
      case 'remuneracion':
      this.reporteUsabilidad(
        'Otros',
        `Certificado de Remuneraciones de los últimos ${this.option} meses`,
        this.emailed
          ? `Envío de Certificado de Remuneraciones de los últimos ${this.option} meses a correo electrónico`
          : `Error en envío de Certificado de Remuneraciones de los últimos ${this.option} meses a correo electrónico`,
        this.email,
        this.emailed
      );
      break;
      case 'antecedentes':
      this.reporteUsabilidad(
        'Otros',
        `Antecedentes Previsionales`,
        this.emailed
          ? `Envío de Antecedentes Previsionales a correo electrónico`
          : `Error en envío de Antecedentes Previsionales a correo electrónico`,
        this.email,
        this.emailed
      );
      break;
      case 'ulti-mov':
      this.reporteUsabilidad(
        'Otros',
        `Últimos Movimientos de los últimos ${this.option} meses`,
        this.emailed
          ? `Envío de Últimos Movimientos de los últimos ${this.option} meses a correo electrónico`
          : `Error en envío de Últimos Movimientos de los últimos ${this.option} meses a correo electrónico`,
        this.email,
        this.emailed
      );
      break;
      case 'vacaciones':
      this.reporteUsabilidad(
        'Otros',
        `Vacaciones Progresivas`,
        this.emailed
          ? `Envío de Vacaciones Progresivas a correo electrónico`
          : `Error en envío de Vacaciones Progresivas a correo electrónico`,
        this.email,
        this.emailed
      );
      break;
      case 'no-coti':
      this.reporteUsabilidad(
        'Otros',
        `Periodos no Cotizados`,
        this.emailed
          ? `Envío de Periodos no Cotizados a correo electrónico`
          : `Error en envío de Periodos no Cotizados a correo electrónico`,
        this.email,
        this.emailed
      );
      break;

      // Folletos Informativos
      case 'sistPrevisional':
      switch (this.option) {
        case 1:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Beneficiarios del Sistema`,
            this.emailed
              ? `Envío de folleto normativo de Beneficiarios del Sistema a correo electrónico`
              : `Error en envío de folleto normativo de Beneficiarios del Sistema a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 2:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Otros Beneficiarios no Financiados por el Sistema`,
            this.emailed
              ? `Envío de folleto normativo de Otros Beneficiarios no Financiados por el Sistema a correo electrónico`
              : `Error en envío de folleto normativo de Otros Beneficiarios no Financiados por el Sistema a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 3:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Afiliación`,
            this.emailed
              ? `Envío de folleto normativo de Afiliación a correo electrónico`
              : `Error en envío de folleto normativo de Afiliación a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 4:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Cotizaciones`,
            this.emailed
              ? `Envío de folleto normativo de Cotizaciones a correo electrónico`
              : `Error en envío de folleto normativo de Cotizaciones a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 5:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Cuenta de Capitalización Individual de Cotizaciones Obligatorias`,
            this.emailed
              ? `Envío de folleto normativo de Cuenta de Capitalización Individual de Cotizaciones Obligatorias a correo electrónico`
              : `Error en envío de folleto normativo de Cuenta de Capitalización Individual de Cotizaciones Obligatorias a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 6:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Cotizaciones Voluntarias`,
            this.emailed
              ? `Envío de folleto normativo de Cotizaciones Voluntarias a correo electrónico`
              : `Error en envío de folleto normativo de Cotizaciones Voluntarias a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 7:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Ahorro Previsional Voluntario`,
            this.emailed
              ? `Envío de folleto normativo de Ahorro Previsional Voluntario a correo electrónico`
              : `Error en envío de folleto normativo de Ahorro Previsional Voluntario a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 8:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Cuenta de Ahorro Voluntario`,
            this.emailed
              ? `Envío de folleto normativo de Cuenta de Ahorro Voluntario a correo electrónico`
              : `Error en envío de folleto normativo de Cuenta de Ahorro Voluntario a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 9:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Cuenta de Indemnización`,
            this.emailed
              ? `Envío de folleto normativo de Cuenta de Indemnización a correo electrónico`
              : `Error en envío de folleto normativo de Cuenta de Indemnización a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 10:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Depósitos Convenidos`,
            this.emailed
              ? `Envío de folleto normativo de Depósitos Convenidos a correo electrónico`
              : `Error en envío de folleto normativo de Depósitos Convenidos a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 11:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Comisiones`,
            this.emailed
              ? `Envío de folleto normativo de Comisiones a correo electrónico`
              : `Error en envío de folleto normativo de Comisiones a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 12:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Traspaso de las Cuentas Personales`,
            this.emailed
              ? `Envío de folleto normativo de Traspaso de las Cuentas Personales a correo electrónico`
              : `Error en envío de folleto normativo de Traspaso de las Cuentas Personales a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 13:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Sitio Web de la AFP`,
            this.emailed
              ? `Envío de folleto normativo de Sitio Web de la AFP a correo electrónico`
              : `Error en envío de folleto normativo de Sitio Web de la AFP a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 14:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Estado de Cuenta de la Capitalización Individual`,
            this.emailed
              ? `Envío de folleto normativo de Estado de Cuenta de la Capitalización Individual a correo electrónico`
              : `Error en envío de folleto normativo de Estado de Cuenta de la Capitalización Individual a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 15:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Sistema de Consultas y Montos de Ofertas de Pensión`,
            this.emailed
              ? `Envío de folleto normativo de Sistema de Consultas y Montos de Ofertas de Pensión a correo electrónico`
              : `Error en envío de folleto normativo de Sistema de Consultas y Montos de Ofertas de Pensión a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 16:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Bono de Reconocimiento`,
            this.emailed
              ? `Envío de folleto normativo de Bono de Reconocimiento a correo electrónico`
              : `Error en envío de folleto normativo de Bono de Reconocimiento a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 17:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Desafiliaciones`,
            this.emailed
              ? `Envío de folleto normativo de Desafiliaciones a correo electrónico`
              : `Error en envío de folleto normativo de Desafiliaciones a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 18:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Devolución de Pagos en Exceso`,
            this.emailed
              ? `Envío de folleto normativo de Devolución de Pagos en Exceso a correo electrónico`
              : `Error en envío de folleto normativo de Devolución de Pagos en Exceso a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 19:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Sistema Previsional - Reclamos`,
            this.emailed
              ? `Envío de folleto normativo de Reclamos a correo electrónico`
              : `Error en envío de folleto normativo de Reclamos a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
      }
      break;

      // Pensiones
      case 'pensiones':
      switch (this.option) {
        case 1:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Pensiones - Pensión de Vejez`,
            this.emailed
              ? `Envío de folleto normativo de Pensión de Vejez a correo electrónico`
              : `Error en envío de folleto normativo de Pensión de Vejez a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 2:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Pensiones - Pensión de Invalidez`,
            this.emailed
              ? `Envío de folleto normativo de Pensión de Invalidez a correo electrónico`
              : `Error en envío de folleto normativo de Pensión de Invalidez a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 3:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Pensiones - Pensión de Sobrevivencia`,
            this.emailed
              ? `Envío de folleto normativo de Pensión de Sobrevivencia a correo electrónico`
              : `Error en envío de folleto normativo de Pensión de Sobrevivencia a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 4:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Pensiones - Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744`,
            this.emailed
              ? `Envío de folleto normativo de Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744 a correo electrónico`
              : `Error en envío de folleto normativo de Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744 a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
      }
      break;

      // Fondos
      case 'fondos':
      switch (this.option) {
        case 1:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Fondos - Fondos de Pensiones`,
            this.emailed
              ? `Envío de folleto normativo de Fondos de Pensiones a correo electrónico`
              : `Error en envío de folleto normativo de Fondos de Pensiones a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 2:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Fondos - Fondos de Pensiones (A, B, C, D)`,
            this.emailed
              ? `Envío de folleto normativo de Fondos de Pensiones (A, B, C, D) a correo electrónico`
              : `Error en envío de folleto normativo de Fondos de Pensiones (A, B, C, D) a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
      }
      break;

      // Reformas Previsional
      case 'refPrevisional':
      switch (this.option) {
        case 1:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Aporte Previsional Solidario`,
            this.emailed
              ? `Envío de folleto normativo de Aporte Previsional Solidario a correo electrónico`
              : `Error en envío de folleto normativo de Aporte Previsional Solidario a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 2:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Pensión Básica Solidaria`,
            this.emailed
              ? `Envío de folleto normativo de Pensión Básica Solidaria a correo electrónico`
              : `Error en envío de folleto normativo de Pensión Básica Solidaria a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 3:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Afiliado Voluntario`,
            this.emailed
              ? `Envío de folleto normativo de Afiliado Voluntario a correo electrónico`
              : `Error en envío de folleto normativo de Afiliado Voluntario a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 4:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Afiliado Independiente`,
            this.emailed
              ? `Envío de folleto normativo de Afiliado Independiente a correo electrónico`
              : `Error en envío de folleto normativo de Afiliado Independiente a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 5:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Cuenta de Ahorro Previsional Voluntario Colectivo`,
            this.emailed
              ? `Envío de folleto normativo de Cuenta de Ahorro Previsional Voluntario Colectivo a correo electrónico`
              : `Error en envío de folleto normativo de Cuenta de Ahorro Previsional Voluntario Colectivo a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 6:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Compensación Económica en caso de Nulidad o Divorcio`,
            this.emailed
              ? `Envío de folleto normativo de Compensación Económica en caso de Nulidad o Divorcio a correo electrónico`
              : `Error en envío de folleto normativo de Compensación Económica en caso de Nulidad o Divorcio a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 7:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Bonificación por Hijo para la Mujer`,
            this.emailed
              ? `Envío de folleto normativo de Bonificación por Hijo para la Mujer a correo electrónico`
              : `Error en envío de folleto normativo de Bonificación por Hijo para la Mujer a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 8:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Subsidio a Trabajadores Jóvenes`,
            this.emailed
              ? `Envío de folleto normativo de Subsidio a Trabajadores Jóvenes a correo electrónico`
              : `Error en envío de folleto normativo de Subsidio a Trabajadores Jóvenes a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
        case 9:
          this.reporteUsabilidad(
            'Folletos Normativos',
            `Reformas Previsional - Asesor Previsional`,
            this.emailed
              ? `Envío de folleto normativo de Asesor Previsional a correo electrónico`
              : `Error en envío de folleto normativo de Asesor Previsional a correo electrónico`,
            this.email,
            this.emailed
          );
          break;
      }
      break;
    }
  }

  usabilidadPrint() {

    switch (this.certificado) {
      // Certificados AFP
      case 'afiliacion':
        this.reporteUsabilidad(
          `Certificado Afiliación AFP`,
          '',
          this.printed
            ? `Impresión de Certificado de Afiliación AFP`
            : `Error en impresión de Certificado de Afiliación AFP`,
          this.email,
          this.printed
        );
        break;

      // Otros
      case 'cotizacion':
        this.reporteUsabilidad(
          'Otros',
          `Certificado de Cotización de los últimos ${this.option} meses`,
          this.printed
            ? `Impresión de Certificado de Cotización de los últimos ${this.option} meses`
            : `Error en impresión de Certificado de Cotización de los últimos ${this.option} meses`,
          this.email,
          this.printed
        );
        break;
      case 'cartola':
        let periodo: string;
        switch (this.option) {
          case 1:
            periodo = 'última cartola cuatrimestral';
            break;

          case 2:
            periodo = '2 últimas cartolas cuatrimestrales';
            break;

          case 3:
            periodo = '3 últimas cartolas cuatrimestrales';
            break;

          case 4:
            periodo = '4 últimas cartolas cuatrimestrales';
            break;
        }

        this.reporteUsabilidad(
          'Otros',
          `Cartola Cuatrimestral`,
          this.printed
            ? `Impresión de ${ periodo }`
            : `Error en impresión de ${ periodo }`,
          this.email,
          this.printed
        );
        break;
      case 'remuneracion':
        this.reporteUsabilidad(
          'Otros',
          `Certificado de Remuneraciones de los últimos ${this.option} meses`,
          this.printed
            ? `Impresión de Certificado de Remuneraciones de los últimos ${this.option} meses`
            : `Error en impresión de Certificado de Remuneraciones de los últimos ${this.option} meses`,
          this.email,
          this.printed
        );
        break;
      case 'antecedentes':
        this.reporteUsabilidad(
          'Otros',
          `Antecedentes Previsionales`,
          this.printed
            ? `Impresión de Antecedentes Previsionales`
            : `Error en impresión de Antecedentes Previsionales`,
          this.email,
          this.printed
        );
        break;
      case 'ulti-mov':
        this.reporteUsabilidad(
          'Otros',
          `Últimos Movimientos de los últimos ${this.option} meses`,
          this.printed
            ? `Impresión de Últimos Movimientos de los últimos ${this.option} meses`
            : `Error en impresión de Últimos Movimientos de los últimos ${this.option} meses`,
          this.email,
          this.printed
        );
        break;
      case 'vacaciones':
        this.reporteUsabilidad(
          'Otros',
          `Vacaciones Progresivas`,
          this.printed
            ? `Impresión de Vacaciones Progresivas`
            : `Error en impresión de Vacaciones Progresivas`,
          this.email,
          this.printed
        );
        break;
      case 'no-coti':
        this.reporteUsabilidad(
          'Otros',
          `Periodos no Cotizados`,
          this.printed
            ? `Impresión de Periodos no Cotizados`
            : `Error en impresión de Periodos no Cotizados`,
          this.email,
          this.printed
        );
        break;

      // Folletos Informativos
      case 'sistPrevisional':
        switch (this.option) {
          case 1:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Beneficiarios del Sistema`,
              this.printed
                ? `Impresión de folleto normativo de Beneficiarios del Sistema`
                : `Error en impresión de folleto normativo de Beneficiarios del Sistema`,
              this.email,
              this.printed
            );
            break;
          case 2:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Otros Beneficiarios no Financiados por el Sistema`,
              this.printed
                ? `Impresión de folleto normativo de Otros Beneficiarios no Financiados por el Sistema`
                : `Error en impresión de folleto normativo de Otros Beneficiarios no Financiados por el Sistema`,
              this.email,
              this.printed
            );
            break;
          case 3:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Afiliación`,
              this.printed
                ? `Impresión de folleto normativo de Afiliación`
                : `Error en impresión de folleto normativo de Afiliación`,
              this.email,
              this.printed
            );
            break;
          case 4:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Cotizaciones`,
              this.printed
                ? `Impresión de folleto normativo de Cotizaciones`
                : `Error en impresión de folleto normativo de Cotizaciones`,
              this.email,
              this.printed
            );
            break;
          case 5:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Cuenta de Capitalización Individual de Cotizaciones Obligatorias`,
              this.printed
                ? `Impresión de folleto normativo de Cuenta de Capitalización Individual de Cotizaciones Obligatorias`
                : `Error en impresión de folleto normativo de Cuenta de Capitalización Individual de Cotizaciones Obligatorias`,
              this.email,
              this.printed
            );
            break;
          case 6:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Cotizaciones Voluntarias`,
              this.printed
                ? `Impresión de folleto normativo de Cotizaciones Voluntarias`
                : `Error en impresión de folleto normativo de Cotizaciones Voluntarias`,
              this.email,
              this.printed
            );
            break;
          case 7:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Ahorro Previsional Voluntario`,
              this.printed
                ? `Impresión de folleto normativo de Ahorro Previsional Voluntario`
                : `Error en impresión de folleto normativo de Ahorro Previsional Voluntario`,
              this.email,
              this.printed
            );
            break;
          case 8:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Cuenta de Ahorro Voluntario`,
              this.printed
                ? `Impresión de folleto normativo de Cuenta de Ahorro Voluntario`
                : `Error en impresión de folleto normativo de Cuenta de Ahorro Voluntario`,
              this.email,
              this.printed
            );
            break;
          case 9:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Cuenta de Indemnización`,
              this.printed
                ? `Impresión de folleto normativo de Cuenta de Indemnización`
                : `Error en impresión de folleto normativo de Cuenta de Indemnización`,
              this.email,
              this.printed
            );
            break;
          case 10:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Depósitos Convenidos`,
              this.printed
                ? `Impresión de folleto normativo de Depósitos Convenidos`
                : `Error en impresión de folleto normativo de Depósitos Convenidos`,
              this.email,
              this.printed
            );
            break;
          case 11:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Comisiones`,
              this.printed
                ? `Impresión de folleto normativo de Comisiones`
                : `Error en impresión de folleto normativo de Comisiones`,
              this.email,
              this.printed
            );
            break;
          case 12:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Traspaso de las Cuentas Personales`,
              this.printed
                ? `Impresión de folleto normativo de Traspaso de las Cuentas Personales`
                : `Error en impresión de folleto normativo de Traspaso de las Cuentas Personales`,
              this.email,
              this.printed
            );
            break;
          case 13:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Sitio Web de la AFP`,
              this.printed
                ? `Impresión de folleto normativo de Sitio Web de la AFP`
                : `Error en impresión de folleto normativo de Sitio Web de la AFP`,
              this.email,
              this.printed
            );
            break;
          case 14:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Estado de Cuenta de la Capitalización Individual`,
              this.printed
                ? `Impresión de folleto normativo de Estado de Cuenta de la Capitalización Individual`
                : `Error en impresión de folleto normativo de Estado de Cuenta de la Capitalización Individual`,
              this.email,
              this.printed
            );
            break;
          case 15:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Sistema de Consultas y Montos de Ofertas de Pensión`,
              this.printed
                ? `Impresión de folleto normativo de Sistema de Consultas y Montos de Ofertas de Pensión`
                : `Error en impresión de folleto normativo de Sistema de Consultas y Montos de Ofertas de Pensión`,
              this.email,
              this.printed
            );
            break;
          case 16:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Bono de Reconocimiento`,
              this.printed
                ? `Impresión de folleto normativo de Bono de Reconocimiento`
                : `Error en impresión de folleto normativo de Bono de Reconocimiento`,
              this.email,
              this.printed
            );
            break;
          case 17:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Desafiliaciones`,
              this.printed
                ? `Impresión de folleto normativo de Desafiliaciones`
                : `Error en impresión de folleto normativo de Desafiliaciones`,
              this.email,
              this.printed
            );
            break;
          case 18:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Devolución de Pagos en Exceso`,
              this.printed
                ? `Impresión de folleto normativo de Devolución de Pagos en Exceso`
                : `Error en impresión de folleto normativo de Devolución de Pagos en Exceso`,
              this.email,
              this.printed
            );
            break;
          case 19:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Sistema Previsional - Reclamos`,
              this.printed
                ? `Impresión de folleto normativo de Reclamos`
                : `Error en impresión de folleto normativo de Reclamos`,
              this.email,
              this.printed
            );
            break;
        }
        break;

      // Pensiones
      case 'pensiones':
        switch (this.option) {
          case 1:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Pensiones - Pensión de Vejez`,
              this.printed
                ? `Impresión de folleto normativo de Pensión de Vejez`
                : `Error en impresión de folleto normativo de Pensión de Vejez`,
              this.email,
              this.printed
            );
            break;
          case 2:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Pensiones - Pensión de Invalidez`,
              this.printed
                ? `Impresión de folleto normativo de Pensión de Invalidez`
                : `Error en impresión de folleto normativo de Pensión de Invalidez`,
              this.email,
              this.printed
            );
            break;
          case 3:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Pensiones - Pensión de Sobrevivencia`,
              this.printed
                ? `Impresión de folleto normativo de Pensión de Sobrevivencia`
                : `Error en impresión de folleto normativo de Pensión de Sobrevivencia`,
              this.email,
              this.printed
            );
            break;
          case 4:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Pensiones - Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744`,
              this.printed
                ? `Impresión de folleto normativo de Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744`
                : `Error en impresión de folleto normativo de Pensión de Invalidez y Sobrevivencia Causadas y Reguladas por la Ley Nº 16.744`,
              this.email,
              this.printed
            );
            break;
        }
        break;

      // Fondos
      case 'fondos':
        switch (this.option) {
          case 1:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Fondos - Fondos de Pensiones`,
              this.printed
                ? `Impresión de folleto normativo de Fondos de Pensiones`
                : `Error en impresión de folleto normativo de Fondos de Pensiones`,
              this.email,
              this.printed
            );
            break;
          case 2:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Fondos - Fondos de Pensiones (A, B, C, D)`,
              this.printed
                ? `Impresión de folleto normativo de Fondos de Pensiones (A, B, C, D)`
                : `Error en impresión de folleto normativo de Fondos de Pensiones (A, B, C, D)`,
              this.email,
              this.printed
            );
            break;
        }
        break;

      // Reformas Previsional
      case 'refPrevisional':
        switch (this.option) {
          case 1:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Aporte Previsional Solidario`,
              this.printed
                ? `Impresión de folleto normativo de Aporte Previsional Solidario`
                : `Error en impresión de folleto normativo de Aporte Previsional Solidario`,
              this.email,
              this.printed
            );
            break;
          case 2:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Pensión Básica Solidaria`,
              this.printed
                ? `Impresión de folleto normativo de Pensión Básica Solidaria`
                : `Error en impresión de folleto normativo de Pensión Básica Solidaria`,
              this.email,
              this.printed
            );
            break;
          case 3:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Afiliado Voluntario`,
              this.printed
                ? `Impresión de folleto normativo de Afiliado Voluntario`
                : `Error en impresión de folleto normativo de Afiliado Voluntario`,
              this.email,
              this.printed
            );
            break;
          case 4:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Afiliado Independiente`,
              this.printed
                ? `Impresión de folleto normativo de Afiliado Independiente`
                : `Error en impresión de folleto normativo de Afiliado Independiente`,
              this.email,
              this.printed
            );
            break;
          case 5:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Cuenta de Ahorro Previsional Voluntario Colectivo`,
              this.printed
                ? `Impresión de folleto normativo de Cuenta de Ahorro Previsional Voluntario Colectivo`
                : `Error en impresión de folleto normativo de Cuenta de Ahorro Previsional Voluntario Colectivo`,
              this.email,
              this.printed
            );
            break;
          case 6:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Compensación Económica en caso de Nulidad o Divorcio`,
              this.printed
                ? `Impresión de folleto normativo de Compensación Económica en caso de Nulidad o Divorcio`
                : `Error en impresión de folleto normativo de Compensación Económica en caso de Nulidad o Divorcio`,
              this.email,
              this.printed
            );
            break;
          case 7:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Bonificación por Hijo para la Mujer`,
              this.printed
                ? `Impresión de folleto normativo de Bonificación por Hijo para la Mujer`
                : `Error en impresión de folleto normativo de Bonificación por Hijo para la Mujer`,
              this.email,
              this.printed
            );
            break;
          case 8:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Subsidio a Trabajadores Jóvenes`,
              this.printed
                ? `Impresión de folleto normativo de Subsidio a Trabajadores Jóvenes`
                : `Error en impresión de folleto normativo de Subsidio a Trabajadores Jóvenes`,
              this.email,
              this.printed
            );
            break;
          case 9:
            this.reporteUsabilidad(
              'Folletos Normativos',
              `Reformas Previsional - Asesor Previsional`,
              this.printed
                ? `Impresión de folleto normativo de Asesor Previsional`
                : `Error en impresión de folleto normativo de Asesor Previsional`,
              this.email,
              this.printed
            );
            break;
        }
        break;
    }
  }

  //Handle timeout
   async timeout(prom, time, exception){
    let timer;
    return Promise.race([
      prom,
      new Promise((_r, rej) => timer = setTimeout(rej, time, exception))
    ]).finally(() => clearTimeout(timer));
  }
}
