import { IKeyboardLayouts, keyboardLayouts } from 'angular-onscreen-material-keyboard';

export const customMaterialKeyboard: IKeyboardLayouts = {
  ...keyboardLayouts,
  'Rut Layout': {
    name: 'Rut',
    keys: [[['1', '1'], ['2', '2'], ['3', '3']],
    [['4', '4'], ['5', '5'], ['6', '6']],
    [['7', '7'], ['8', '8'], ['9', '9']],
    [['K', 'K'], ['0', '0'], ['Backspace', 'backspace']]],
    lang: ['rut']
  },
  'Numeric Layout': {
    name: 'Numeric',
    keys: [[['1', '1'], ['2', '2'], ['3', '3']],
    [['4', '4'], ['5', '5'], ['6', '6']],
    [['7', '7'], ['8', '8'], ['9', '9']],
    [['0', '0'], ['', ''], ['Backspace', 'backspace']]],
    lang: ['numeric']
  },
  'Email Layout': {
    name: 'Email',
    keys: [
      [['1', '1'], ['2', '2'], ['3', '3'], ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'], ['0', '0'], ['Backspace', 'Backspace']],
      [['q'], ['w'], ['e'], ['r'], ['t'], ['y'], ['u'], ['i'], ['o'], ['p']],
      [['a'], ['s'], ['d'], ['f'], ['g'], ['h'], ['j'], ['k'], ['l'], ['ñ']],
      [['z'], ['x'], ['c'], ['v'], ['b'], ['n'], ['m'], ['_'],['-'], ['.'], ['@'], ['.cl'], ['.com']],
      [['@gmail.com'], ['@hotmail.com'], ['@outlook.com']]
    ],
    lang: ['email']
  }
};
