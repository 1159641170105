import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { UserIdleModule } from 'angular-user-idle';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HomeComponent } from './modules/home/home.component';
import { MenuComponent } from './modules/menu/menu.component';
import { SharedModule } from './modules/shared/shared.module';

// Teclado virtual
import { MatKeyboardModule, MAT_KEYBOARD_LAYOUTS } from 'angular-onscreen-material-keyboard';
import { customMaterialKeyboard } from './custom/keyboard';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    SharedModule,
    UserIdleModule.forRoot({ idle: 240, timeout: 60 }),
    MatKeyboardModule
  ],
  providers: [
    {
      provide: MAT_KEYBOARD_LAYOUTS,
      useValue: customMaterialKeyboard
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
