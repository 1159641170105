<div *ngIf="view == 'identi'">
  <app-header [title]="'Autentificación'" [changeClass]="'display-3 din-black pt-4 pl-5'" [divClass]="'text-left pl-5'"></app-header>

  <div class="row">
      <div class="col-6 offset-2">
          <div class="box-texto mt-4 "></div>
      </div>

      <form #afiliacionForm="ngForm" (ngSubmit)="afiliacionForm.form.valid && onSubmit(afiliacionForm.value)">
        <div class="col-6 offset-3 mt-n5 text-center">
          <h1 class="din-black grey display-4 mb-4">Ingrese RUT</h1>

          <input class="font-weight-normal" type="text" id="rutCliente" #rutCliente="ngModel" [(ngModel)]="rut" name="rutCliente" minlength="3" [ngClass]="{ 'is-invalid': afiliacionForm.submitted && rutCliente.invalid }" required formatRut validateRut readonly>
            <div *ngIf="afiliacionForm.submitted && rutCliente.invalid" class="invalid-feedback">
            <div *ngIf="rutCliente.errors.required">Por favor ingrese RUT.</div>
            <div *ngIf="rutCliente.errors.invalidRut || rutCliente.errors.minlength && !rutCliente.errors.required"> 
              Por favor ingrese un rut válido.
            </div>
          </div>
        </div>

        <div class="col-4 offset-4 mt-3">
          <div class="row">
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('1')">1</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('2')">2</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('3')">3</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('4')">4</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('5')">5</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('6')">6</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('7')">7</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('8')">8</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('9')">9</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('K')">K</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="teclado('0')">0</div>
            </div>
            <div class="col-4">
              <div class="btn-login-number" (click)="clear()"><i class="fas fa-backspace"></i></div>
            </div>

          </div>
          <button class="btn-series-print w-100 mt-5" type="submit">Aceptar</button>
        </div>
      </form>
  </div>

  <app-footer [step]="'menu'"></app-footer>
</div>


<div class="row text-center" *ngIf="view == 'autentia'">
  <div class="logo">
    <img src="assets/img/top-modulo.jpg">
  </div>
  <div class="col-12" style="z-index: 999;">
    <img src="assets/img/identidad-huella.png">
  </div>
  <footer class="img-background-footer"></footer>
</div>

<app-opcion-entrega *ngIf="view == 'entrega'" [back]="'identi'" [module]="module" (changeStep)="view = $event"></app-opcion-entrega>