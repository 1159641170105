<!-- Opciones de entrega -->
<div *ngIf="view == 'opc-entrega' || print == 'print' && view != 'confirmPrint'">
  <div class="row text-center">
    <div class="col-12">
      <div class="logo">
        <img src="./assets/img/top-modulo.jpg">
      </div>
    </div>

    <div class="col-12 text-center">
      <img src="./assets/img/ejecutiva-totem.png">
    </div>

    <div class="col-12 mt-5 pt-5 mb-5">
      <h1 class="din-black grey display-4 animated bounceIn">Elige ¿Cómo te entregamos<br> el documento?</h1>
      <h1 class="din-black grey display-4 animated bounceIn">Toca el botón para finalizar</h1>
    </div>
    <div class="col-12 mt-5 animated bounceIn mb-5">
      <img src="assets/img/send-email.png" (click)="menu('email')">
    </div>

    <div class="col-12 mt-5 animated bounceIn">
      <img src="assets/img/btn-print.png" (click)="menu('print')">
    </div>
  </div>
  <app-footer [back]="back" [step]="step" (changeStep)="volver($event)"></app-footer>
</div>


<!-- Enviar Email -->
<app-send-email *ngIf="view == 'email'" [source]="source" [certificado]="module" [option]="option" [toprint]="print" (changeStep)="view = $event"></app-send-email>
<!-- Después de solo Imprimir -->
<app-confirmacion *ngIf="view == 'confirmPrint'" [step]="'menu'"></app-confirmacion>
