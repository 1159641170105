import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class OtrosCertificadosService {

  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });
​
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  async emitirCertAfiliacion(rut: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/CertAfiliacion/EmitirCertAfiliacion?rut=${rut}`;

    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async emitirAntecedentesPrevi(rut: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/OtrosCert/AntecePrevisionales?rut=${rut}`;

    try {
      return await this.httpClient.get(url).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async emitirUltMovimientos(rut: string, tipoProducto: string, tipoFondo: string, perDesde: string, perHasta: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/OtrosCert/UltimostMovimientos`;

    try {
      return await this.httpClient.post(url, { rut, tipoProducto, tipoFondo, perDesde, perHasta }, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async antecedentesCuentaCertMov(rut: string, tipoProd: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/OtrosCert/AntecedentesCuentaCertMov`;

    try {
      return await this.httpClient.post(url, { rut, tipoProd }, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async emitirVacacionesProgre(rut: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/OtrosCert/VacacionesProgre?rut=${rut}`;

    try {
      return await this.httpClient.get(url).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async emitirPeriodoNoCoti(rut: string, desde: string, hasta: string) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/OtrosCert/PeriodosNoCotizados?rut=${rut}&fechaDesde=${desde}&fechaHasta=${hasta}`;

    try {
      return await this.httpClient.get(url).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }
}
