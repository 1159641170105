import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { PrintService } from 'src/app/core/services/http/print.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  version: string;
  disabled = false;

  constructor(
    private router: Router,
    private printService: PrintService,
    private sweetAlertService: SweetAlertService
  ) { }

  async ngOnInit() {
    this.version = environment.version;
    await this.getTotemInfo();
  }

  async getTotemInfo(): Promise<void> {
    this.sweetAlertService.swalLoading();
    const response = await this.printService.totemInfo();
    this.sweetAlertService.swalClose();

    if (response['status']) {
      localStorage.setItem('totemInfo', JSON.stringify(response['data']));
    }
  }

  async getAuthorization(event: any) {
    event.preventDefault();
    this.router.navigate(['menu']);
  }
}
