import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Output() changeStep: EventEmitter<any> = new EventEmitter();
  @Input() back = '';
  @Input() step: string;
  @Input() module: string;

  constructor(private router: Router) { }

  ngOnInit() {

  }

  change(e: any, action: string) {
    e.preventDefault();
    if (action === 'volver') {
      if (this.back === '' || this.back === undefined) {
        this.router.navigate([this.step]);
      } else {
        this.changeStep.emit(this.back);
      }
    } else {
      this.router.navigate(['exit']);
    }
  }
}
