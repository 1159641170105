<div *ngIf="view == 'email' && toprint == ''">
  <div class="row text-center">
    <div class="col-12">
      <div class="logo">
        <img src="./assets/img/top-modulo.jpg">
      </div>
    </div>

    <div *ngIf="showView" class="col-12">
      <div class="col-12 text-center">
        <img src="./assets/img/registra-correo.png ">
      </div>
      <div class="col-12 text-center mt-5">
        <img src="./assets/img/mail.png ">
      </div>

      <form [formGroup]="emailForm" class="row mt-5 mb-5 w-100" (ngSubmit)="onSubmit()">
        <div  class="col-12 mt-5 text-center">
          <h1 class="din-black grey">Ingresa tu Correo electrónico</h1>
          <input type="text" class="input-mail" formControlName="email" readonly [ngClass]="{ 'is-invalid': submitted && f.email.errors }" [matKeyboard]="'email'">

          <h1 class="din-black grey mt-3">Confirma tu Correo electrónico</h1>
          <input type="text" class="input-mail" formControlName="confirm" readonly [ngClass]="{ 'is-invalid': submitted && (isEmailMismatch || f.confirm.errors) }" [matKeyboard]="'email'">
        </div>

        <div class="col-4 offset-4 mt-5 mb-5">
          <button type="submit" class="btn-series-print" [disabled]="f.email.value.length == 0 && f.confirm.value.length == 0">Aceptar</button>

          <button type="button" (click)="onReset()" class="btn-series-print-dark mt-2" [disabled]="f.email.value.length == 0 && f.confirm.value.length == 0">Borrar</button>
        </div>
      </form>
    </div>
  </div>
  <app-footer [back]="'opc-entrega'" (changeStep)="volver($event)" *ngIf="showView"></app-footer>
</div>

<!--Exitoso -->
<app-email-sent *ngIf="view == 'confirm'" [action]="action" [email]="email"></app-email-sent>


<!-- <app-confirmacion *ngIf="view == 'confirmPrint'" [step]="'menu'"></app-confirmacion> -->
