<ng-container *ngIf="!viewCertif">
  <div class="row text-center">
    <div class="col-12">
      <div class="logo">
          <img src="assets/img/top-modulo.jpg">
      </div>
    </div>
    <div class="col-8 pl-0">
      <div class="title-modelo text-left pl-5">
        <h1 class="din-black pt-n4 pl-5 big-2">Bienvenido,
          <p class="display-4 din-regular pt-n5">Elige lo que necesitas</p>
        </h1>
      </div>
    </div>
    <div class="col-2">
      <img src="assets/img/ejecutiva-totem.png" class="img-title">
    </div>
  </div>

  <div class="row">
    <div class="box-texto"></div>
    <div class="col-12 text-center">
      <button class="btn-serie animated bounceInLeft faster" (click)="menu($event, 'clave-web')">
        Crear o Modificar <br> tu Clave Web
      </button>

      <button class="btn-serie animated bounceInLeft fast" (click)="menu($event, 'certificados')">
        Certificados
      </button>

      <button class="btn-serie animated bounceInLeft slow" (click)="menu($event, 'folletos-normativos')">
        Información del <br> Sistema de Pensiones
      </button>
    </div>
  </div>

  <div class="row text-center botonera w-100">
    <div class="col-12  animated bounceIn slow">
      <a href="#" (click)="menu($event, 'exit')">
        <img src="assets/img/salir-big.png">
      </a>
    </div>
  </div>
</ng-container>


  <!-- Menú Certificados -->
<ng-container *ngIf="viewCertif">
  <app-header [title]="'Certificados'" [changeClass]="'display-2 din-black pt-4'"></app-header>
  <div class="row">
    <div class="col-6 offset-2">
      <div class="box-texto mt-4">
        <svg width="700" height="136" viewBox="0 0 700 136">
          <text transform="translate(0 64)" fill="#464646" font-size="62" font-family="DIN-BlackAlternate, DIN BlackAlternate" font-weight="800">
            <tspan x="0" y="0">Elige una opción</tspan>
          </text>
        </svg>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center">
      <button class="btn-serie animated bounceInLeft faster" (click)="menu($event, 'certificado-afiliacion')">
        Certificado Afiliación de AFP
      </button>

      <button class="btn-serie animated bounceInLeft fast" (click)="menu($event, 'otros-tramites')">
        Certificados y Cartolas
      </button>
    </div>
  </div>

  <div class="row text-center botonera w-100">
    <div class="col-8 offset-2">
      <div class="row">
        <div class="col-6 animated bounceIn slow">
          <a href="#" (click)="change($event, 'volver')"><img src="assets/img/btn-volver.png"></a>
        </div>
        <div class="col-6 animated bounceIn slow">
          <a href="#" (click)="change($event, 'exit')"><img src="assets/img/btn-salir.png"></a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<footer class="img-background-footer"></footer>

