<div class="row text-center">
  <div class="col-12">
    <div class="logo">
      <img src="assets/img/top-modulo.jpg">
    </div>
  </div>

  <div class="col-12 mt-5 pt-5 mb-5">
    <h1 class="din-black grey display-3">¡Que tengas un <br> excelente día!</h1>
  </div>

  <div class="col-12 text-center mt-5">
    <img src="assets/img/ejecutiva-totem.png" class="img-need animated bounceIn">
  </div>

  <div class="col-12 mt-5 pt-5">
    <h1 class="din-black grey display-3">¡Hasta pronto!</h1>
  </div>
  <div class="col-12">
    <img src="assets/img/heart.png">
  </div>
</div>

<footer class="img-background-footer"></footer>