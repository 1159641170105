<ng-container *ngIf="module">
  <div class="row text-center mb-5" *ngIf="view">
    <div class="col-12">
      <div class="logo">
        <img src="./assets/img/top-modulo.jpg">
      </div>
    </div>
  </div>

  <div class="row text-center" *ngIf="view === 'email'">
    <div class="col-12 pt-5">
      <img src="./assets/img/send-mail.png" class="mb-3">
      <h1 class="din-black green display-4 animated bounceIn">{{ email }}</h1>
    </div>
  </div>

  <div class="row text-center" *ngIf="view === 'print'">

    <div class="col-12 mt-5" *ngIf="action === 'print'">
      <img src="./assets/img/box-texto-3.png">
    </div>

    <div class="col-12 mt-5 pt-5 send-print">
      <h1 class="din-regular grey display-4 animated bounceIn pt-4">También ha sido enviado <br> a tu correo </h1>
      <h1 class="din-black green display-4 animated bounceIn">{{ email }}</h1>
      <img src="./assets/img/mail.png" class="mt-5">
    </div>
  </div>


  <div class="row text-center" *ngIf="view === 'suscribirse'">
    <div class="col-12 text-center">
      <img src="./assets/img/ejecutiva-totem.png">
    </div>

    <div class="col-12 mt-5 pt-5">
      <h1 class="din-black grey display-4 animated bounceIn">¿Deseas dejar tu correo</h1>
      <h1 class="din-black green display-4 animated bounceIn">{{ email }}</h1>
      <h1 class="din-black grey display-4 animated bounceIn"> para futuras comunicaciones de <br> AFP Modelo?</h1>
    </div>
    <div class="col-4 offset-4 mt-5 animated bounceIn">
      <button class="btn-series-print" (click)="opcion('si')">SI</button>
      <button class="btn-series-print-dark mt-2" (click)="opcion('no')">NO</button>
    </div>
  </div>

  <footer class="img-background-footer"></footer>
</ng-container>

<app-confirmacion *ngIf="!module" [step]="'menu'"></app-confirmacion>
