<ng-container *ngIf="module === 'confirm'">

  <div class="row text-center">
      <div class="col-12">
          <div class="logo">
              <img src="./assets/img/top-modulo.jpg">
          </div>
      </div>

      <div class="col-12 text-center">
          <img src="./assets/img/ejecutiva-totem.png" class="img-need">

      </div>

      <div class="col-12 mt-5 pt-5">
          <h1 class="din-black grey display-4 animated bounceIn">¿Necesitas hacer<br> algo más?</h1>
      </div>
      <div class="col-4 offset-4 mt-5 animated bounceIn">
          <button class="btn-series-print" (click)="opcion('si')">SI</button>
          <button class="btn-series-print-dark mt-2" (click)="opcion('no')">NO</button>
      </div>
  </div>
  <footer class="img-background-footer"></footer>
</ng-container>

<ng-container *ngIf="module === 'exit'">
  <app-exit></app-exit>
</ng-container>