import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { Ng9RutModule } from 'ng9-rut';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ConfirmacionComponent } from './confirmacion/confirmacion.component';
import { AutenticacionComponent } from './autenticacion/autenticacion.component';
import { ExitComponent } from './exit/exit.component';
import { OpcionEntregaComponent } from './opcion-entrega/opcion-entrega.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { EmailSentComponent } from './email-sent/email-sent.component';

// Teclado virtual
import { MatKeyboardModule, MAT_KEYBOARD_LAYOUTS } from 'angular-onscreen-material-keyboard';
import { customMaterialKeyboard } from '../../custom/keyboard';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ConfirmacionComponent,
    AutenticacionComponent,
    ExitComponent,
    OpcionEntregaComponent,
    SendEmailComponent,
    EmailSentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    Ng9RutModule,
    MatKeyboardModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ConfirmacionComponent,
    AutenticacionComponent,
    ExitComponent,
    OpcionEntregaComponent,
    SendEmailComponent,
    EmailSentComponent
  ],
  providers: [
    {
      provide: MAT_KEYBOARD_LAYOUTS,
      useValue: customMaterialKeyboard
    },
  ],
})
export class SharedModule { }
