<div class="index-modelo">
  <a href="#" (click)="getAuthorization($event)">
    <div class="row text-center">
      <div class="col-12 offset-5 ">
        <div class="logo"></div>
      </div>
      <div class="col-12 logos-other">
        <h1 class="display-2 mb-5 pb-5 mb-5 mt-n5 medium animated bounceIn slower ">BIENVENIDO A</h1>
        <img src="assets/img/logo-modelo.png" class="mb-5">
        <h1 class="display-3 medium">TOCA AQUÍ</h1>
        <H2 class="display-4 mb-5 medium pb-5">PARA COMENZAR</H2>
      </div>

      <div class="col-12 title-welcome">
        <img src="assets/img/home-img.gif">
      </div>
    
      <div class="col-2 offset-5 mt-5"></div>

    </div>

    <footer>
      <p style="position: absolute; left: 2%; top: 30%; font-size: x-small">
        v{{ version }}
      </p>
      <img src="assets/img/powered.png">
    </footer>
  </a>

</div>
