<div class="row text-center">
  <div class="col-12">
    <div class="logo">
      <img src="assets/img/top-modulo.jpg">
    </div>
  </div>
  <div class="col-8 pl-0">
    <div class="title-modelo {{ divClass }}">
      <h1 class="{{ changeClass }}">{{ title }}</h1>
    </div>
  </div>
  <div class="col-2">
    <img src="assets/img/ejecutiva-totem.png" class="img-title">
  </div>
</div>
