import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/core/services/http/auth.service';
import { SweetAlertService } from 'src/app/core/services/sweet-alert/sweet-alert.service';
import { NgForm } from '@angular/forms';
import { ReporteriaService } from '../../../core/services/http/reporteria/reporteria.service';
import { Router } from '@angular/router';
import { OtrosCertificadosService } from 'src/app/core/services/http/otros-certificados.service';

@Component({
  selector: 'app-autenticacion',
  templateUrl: './autenticacion.component.html',
  styleUrls: ['./autenticacion.component.css']
})
export class AutenticacionComponent implements OnInit {
  @Output() changeStep = new EventEmitter<any>();
  @Input() step: string;
  @Input() module: string;
  view = 'identi';
  rut = '';

  constructor(
    private authService: AuthService,
    private sweetAlertService: SweetAlertService,
    private reporteriaService: ReporteriaService,
    private router: Router,
    private certificadosService: OtrosCertificadosService
  ) {}

  ngOnInit() {}

  teclado(num: any) {
    if (this.rut.length < 9) {
      this.rut += num;
    }
  }

  clear() {
    this.rut = this.rut.substring(0, this.rut.length - 1);
  }

  async onSubmit(form: NgForm) {
    const rut = form['rutCliente'].substring(0, form['rutCliente'].length - 1);
    const dv = form['rutCliente'].substring(form['rutCliente'].length - 1);
    const rutCliente = `${rut}-${dv}`;

    localStorage.setItem('afpRutAfil', `${rut}${dv}`);

    this.sweetAlertService.swalLoading();
    const respInfo = await this.certificadosService.emitirCertAfiliacion(rutCliente);
    this.sweetAlertService.swalClose();

    if (respInfo['status']) {

      if (this.module === 'afiliacion') {
        this.view = 'entrega';
      } else {
        this.view = 'autentia';
        const response = await this.authService.validarLogin(rut, dv);

        if (response['status']) {
          this.reporteUsabilidad(
            'Autenticación con huella dactilar',
            '',
            'Autenticación con huella dactilar exitosa',
            '',
            true,
            rut + dv
          );
        const userData = await this.authService.getUserInfo(rutCliente);
          if(userData['status']){
            localStorage.setItem('userFechaNac', userData['data']['fecNacimiento']);
            this.changeStep.emit(this.step);
            localStorage.setItem('afpRut', `${rut}${dv}`);
            this.reporteUsabilidad(
              'Se obtienen datos del usuario desde AFP Modelo.',
              '',
              'Obtención de datos del usuario desde AFP Modelo. Exitoso',
              '',
              true,
              rut + dv
            );
           
          } else {
            const respSwal = await this.sweetAlertService.swalErrorAuth();

            if (respSwal) {
             this.router.navigate(['exit']);
            }
            this.reporteUsabilidad(
             'Se obtienen datos del usuario desde AFP Modelo.',
             '',
             'Hubo un error al obtener los datos del usuario desde AFP Modelo.',
             '',
             false,
             rut + dv
           );
          }

         } else {
           const respSwal = await this.sweetAlertService.swalErrorAuth();

           if (respSwal) {
            this.router.navigate(['exit']);
           }

           this.reporteUsabilidad(
            'Autenticación con huella dactilar',
            '',
            'Ha ocurrido un error en la autenticación con huella dactilar',
            '',
            false,
            rut + dv
          );
         }
      }
    } else {
      this.sweetAlertService.swalErrorPromise('No estás afiliado en AFP Modelo, haz el cambio de AFP acercándote a un anfitrión.');
    }
  }

  reporteUsabilidad(modulo: string, recurso: string, respuesta: string, correo: string, status: boolean, rut: string) {
    this.reporteriaService.saveLog(
      modulo,
      recurso,
      respuesta,
      'Front-End',
      correo,
      status,
      rut
    );
  }
}
