import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReporteriaService {
  configuracion: any;
  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getConfig() {
    return this.httpClient.get('./assets/config/config.json');
  }

  saveLog(Modulo: string, Recurso: string, Respuesta: string, Stack: string, Correo: string, Estatus: boolean, Rut = null) {
    this.configuracion = this.getConfig();
    this.configuracion.subscribe((response: any) => {
      const API_URL = `${ response['api_usabilidad'] }/usabilidad/insert`;
      const totem = JSON.parse(localStorage.getItem('totemInfo'));

      const data = {
        NombreTotem: totem['nombreTotem'],
        Sucursal: totem['nombreOficina'],
        Proyecto: response['Proyecto'],
        Modulo,
        Recurso,
        Respuesta,
        Stack,
        Correo,
        Estatus,
        Rut
      };

      return this.httpClient.post(API_URL, data, { headers: this.headers }).subscribe();
    });
  }
}
