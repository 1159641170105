import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  @Output() confirmation: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();

  toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true
  });
  private readonly _cancelPrintSource = new BehaviorSubject<Boolean>(false);
  readonly cancelPrint$ = this._cancelPrintSource.asObservable();
  constructor() { }

  swalInfo(message: string): void {
    Swal.fire(message);
  }

  async swalErrorCustom(message: string): Promise<any> {
    let response: any;
    await Swal.fire({
      html:
        '<div class="row  text-center">' +
          '<div class="col-12 mb-4 mt-1">' +
              '<img src="./assets/img/x-big.png">' +
          '</div>' +
        '</div>' +
        '<div class="mt-4">' +
            '<h1 class="din-black light-grey">' + message  + '</h1>' +
        '</div>',
      confirmButtonText: 'Volver',
      width: '700px',
      allowOutsideClick: false,
      customClass: {
        popup: 'border-green',
        confirmButton: 'confirm-button-class'
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });

    return response;
  }
   swalLoadingCustomMessage(message: string){
    Swal.fire({
      html:
        '<div class="row  text-center">' +
          '<div class="col-12 mb-4 mt-1">' +
              '<img src="./assets/img/icon-alert.png">' +
          '</div>' +
        '</div>' +
        '<div class="mt-4">' +
            '<h1 class="din-black light-grey">' + message  + '</h1>' +
        '</div>',
      width: '700px',
      allowOutsideClick: false,
      showConfirmButton: false,
      customClass: {
        popup: 'border-green',
        confirmButton: 'confirm-button-class'
      }
    })
  }

  swalError(): void {
    Swal.fire({
      html: '<div class="row text-center">' +
      '<div class="col-12 mb-4 mt-1">' +
          '<img src="./assets/img/x-big.png">' +
      '</div>' +
    '</div>' +
    '<div class="col-12">' +
        '<h1 class="din-black grey display-4">Error al imprimir</h1>' +
    '</div>' +
    '<div class="mt-4">' +
        '<h1 class="din-black light-grey">Por favor, intente nuevamente</h1>' +
    '</div>',
      confirmButtonText: 'Volver',
      width: '700px',
      customClass: {
        popup: 'modal-content',
        confirmButton: 'col-6 offset-3 mt-5 btn-series-print'
      }
    });
  }

  async swalErrorAuth() {
    let response = false;
    await Swal.fire({
      html: '<div class="row text-center">' +
      '<div class="col-12 mb-4 mt-1">' +
          '<img src="./assets/img/icon-alert.png">' +
      '</div>' +
    '</div>' +
    '<div class="col-12">' +
        '<h1 class="din-black grey display-4">No hemos podido validar tu identidad por este medio</h1>' +
    '</div>' +
    '<div class="mt-4">' +
        '<h1 class="din-black light-grey">Acércate al anfitrión para ser asistido por uno de nuestros ejecutivos con un número de atención</h1>' +
    '</div>',
      confirmButtonText: 'Entendido',
      width: '700px',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'col-6 offset-3 mt-5 btn-series-print'
      }
    }).then((result) => {
      if (result.value) {
        response = true;
      }
    });
    return response;
  }

  async swalErrorEmail(message) {
    Swal.fire({
      html: '<div class="row text-center">' +
              '<div class="col-12 mb-4 mt-1">' +
                '<img src="./assets/img/icon-alert.png">' +
              '</div>' +
            '</div>' +
            '<div class="col-12">' +
              '<h1 class="din-black grey display-4">' + message + '</h1>' +
            '</div>' +
            '<div class="mt-4">' +
              '<h1 class="din-black light-grey">Inténtalo nuevamente</h1>' +
            '</div>',
      confirmButtonText: 'Entendido',
      width: '700px',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'col-6 offset-3 mt-5 btn-series-print'
      }
    });
  }

  swalConfirmDialog(data): void {
    Swal.fire({
      title: '¿Estás seguro?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#36ABAC',
      cancelButtonColor: '#E06162',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Si, eliminar'
    }).then((result) => {
      if (result.value) {
        this.confirmation.emit(data);
      }
    });
  }

  swalClavesNoCoinciden(): void {
    Swal.fire({
      html:
        '<div class="row  text-center">' +
          '<div class="col-12 mb-4 mt-1">' +
              '<img src="./assets/img/icon-alert.png">' +
          '</div>' +
        '</div>' +
        '<div class="col-12">' +
            '<h1 class="din-black grey display-4">Las claves ingresadas<br> no coinciden</h1>' +
        '</div>' +
        '<div class="mt-4">' +
            '<h1 class="din-black light-grey">Inténtalo nuevamente</h1>' +
        '</div>',
      confirmButtonText: 'Volver',
      width: '700px',
      customClass: {
        popup: 'border-green',
        confirmButton: 'confirm-button-class'
      }
    });
  }

  swalClaveWebNoCumple(validaciones={
    maxNumeros:true,
    correlativo:true,
    rut:true,
    fechaNacimiento:true,
    ultimasClaves:true,
    numerosRepetidos:true
  }): void {

    Swal.fire({
      html:
        '<div class="row  text-center">' +
          '<div class="col-12 mb-4 mt-1">' +
              '<img src="./assets/img/icon-alert.png">' +
          '</div>' +
          '<div class="col-12">'  +
              '<h1 class="din-black grey display-4">Ingresa nuevamente, la clave no cumple con lo antes solicitado:</h1>'  +
          '</div>'  +
        '</div>' +
        '<div class="col-10 offset-2 text-left">' +
          `<p class="parrafo-modal"><img src="./assets/img/${validaciones.maxNumeros ? 'check':'x'}.png" style="width: 28px;height: 28px;"> Tu nueva Clave debe contener 6 números.</p>` +
          `<p class="parrafo-modal"><img src="./assets/img/${validaciones.correlativo ? 'check':'x'}.png" style="width: 28px;height: 28px;"> Tu clave no puede tener números correlativos. Por ejemplo: 123, 321, etc.</p>` +
          `<p class="parrafo-modal"><img src="./assets/img/${validaciones.fechaNacimiento ? 'check':'x'}.png" style="width: 28px;height: 28px;"> No debe llevar tu fecha de nacimiento.</p>` +
          `<p class="parrafo-modal"><img src="./assets/img/${validaciones.numerosRepetidos ? 'check':'x'}.png" style="width: 28px;height: 28px;"> No puedes repetir 3 veces un mismo número. Ejemplo: 111, 444, 555, etc.</p>` +
          `<p class="parrafo-modal"><img src="./assets/img/${validaciones.rut ? 'check':'x'}.png" style="width: 28px;height: 28px;"> No debes ingresar tu RUT o una parte de este.</p>` +
          `<p class="parrafo-modal"><img src="./assets/img/${validaciones.ultimasClaves ? 'check':'x'}.png" style="width: 28px;height: 28px;"> Tu clave no puede ser similar a las últimas 6 creadas</p>` +
        '</div>' +
        '<div class="mt-4">' +
            '<h1 class="din-black light-grey">Inténtalo nuevamente</h1>' +
        '</div>',
      confirmButtonText: 'Volver',
      width: '700px',
      customClass: {
        popup: 'border-green',
        confirmButton: 'confirm-button-class'
      }
    });
  }

  swalPrint(timeout): void {
    let demoraTimeout
    Swal.fire({
      imageUrl: './assets/img/print.gif',
      title: 'Imprimiendo',
      html:'<div class="d-flex w-100 justify-content-center align-items-center mt-5"><button class="btn btn-danger btn-cancelar-impresion" style="display:none"><h3>Cancelar impresión</h3></button></div>',
      onOpen: () => {
        Swal.getHtmlContainer().querySelector('button').addEventListener('click',()=>{
          this._cancelPrintSource.next(true);
        })
        demoraTimeout = setTimeout(()=>{
           Swal.getHtmlContainer().querySelector('button').style.display = 'flex';
        },timeout);
      },
      onClose: () => {
        clearTimeout(demoraTimeout)
        this._cancelPrintSource.next(false);
      },
      width: '110%',
      position: 'center',
      showConfirmButton: false,
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-print',
        title: 'din-black grey ',
      }
    });
  }

  swalPrintSinTimeout(): void {
    Swal.fire({
      imageUrl: './assets/img/print.gif',
      title: 'Imprimiendo',
      width: '110%',
      position: 'center',
      showConfirmButton: false,
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-print',
        title: 'din-black grey ',
      }
    });
  }

  swalLoading(): void {
    const html = '<div><img src="assets/img/loading.gif"></div>';
    Swal.fire({
      html,
      position: 'center',
      showConfirmButton: false,
      background: 'bottom',
      backdrop: 'rgba(255,255,255,0.4)',
      allowOutsideClick: false,
      customClass: {
        title: 'spinner-border'
      }
    });
  }

  swalErrorPromise(message: string): Promise<any> {
    return Swal.fire({
      html: '<div class="row text-center">' +
      '<div class="col-12 mb-4 mt-1">' +
          '<img src="./assets/img/icon-alert.png">' +
      '</div>' +
    '</div>' +
    '<div class="mt-4">' +
        '<h1 class="din-black light-grey">' + message + '</h1>' +
    '</div>',
      confirmButtonText: 'Volver',
      width: '700px',
      allowOutsideClick: false,
      customClass: {
        popup: 'modal-content',
        confirmButton: 'col-6 offset-3 mt-5 btn-series-print'
      }
    });
  }

  swalClose(): void {
    Swal.close();
  }
  swalClickClose(): void {
    Swal.clickCancel();
  }

  successToast(message: string) {
    this.toast.fire({
      icon: 'success',
      title: message
    });
  }

  errorToast(message: string) {
    this.toast.fire({
      icon: 'error',
      title: message
    });
  }

  warningToast(message: string) {
    this.toast.fire({
      icon: 'warning',
      title: message
    });
  }

  infoToast(message: string) {
    this.toast.fire({
      icon: 'question',
      title: message
    });
  }
}
