<div class="row text-center botonera w-100">
  <div class="col-8 offset-2">
    <div class="row">
      <div class="col-6 animated bounceIn slow">
        <a href="#" (click)="change($event, 'volver')"><img src="assets/img/btn-volver.png"></a>
      </div>
      <div class="col-6 animated bounceIn slow">
        <a href="#" (click)="change($event, 'salir')"><img src="assets/img/btn-salir.png"></a>
      </div>
    </div>
  </div>
</div>
<footer class="img-background-footer"></footer>

