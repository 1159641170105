import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { Observable, } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  headers: HttpHeaders = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8'
  });
​
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  async totemInfo() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['impresion']}/TotemInfo`;
    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async sendEmailUrl(data) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/EnvioEmail/EnviarEmailUrl`;
    try {
      return await this.httpClient.post(url, data, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async sendEmailBase64(data) {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['endpoint']}/EnvioEmail/EnviarEmail`;
    try {
      return await this.httpClient.post(url, data, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }

  async laserStatus() {
    const endpoint = await this.configService.getConfig();
    const url = `${endpoint['impresion']}/LaserStatus`;
    try {
      return await this.httpClient.get(url, { headers: this.headers }).toPromise();
    } catch (error) {
      return { status: false, code: error.code, message: error.message };
    }
  }
   printUrl(docUrl: string, endpoint:any):Observable<any> {
    const url = `${endpoint['impresion']}/PrintLaserURL`;
    return  this.httpClient.post(url, { document: docUrl },  { headers: this.headers });
  }
   printBase64(base64: string, endpoint:any) {
    const url = `${endpoint['impresion']}/PrintLaser`;
    return  this.httpClient.post(url, { document: base64 },  { headers: this.headers });
  }
}
