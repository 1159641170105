import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { HomeComponent } from './modules/home/home.component';
import { MenuComponent } from './modules/menu/menu.component';
import { ExitComponent } from './modules/shared/exit/exit.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'menu',
    component: MenuComponent
  },
  {
    path: 'certificado-afiliacion',
    loadChildren: () => import('./modules/afiliacion/afiliacion.module').then(m => m.AfiliacionModule)
  },
  {
    path: 'otros-tramites',
    loadChildren: () => import('./modules/otros-tramites/otros-tramites.module').then(m => m.OtrosTramitesModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/folletos/folletos.module').then(m => m.FolletosModule)
  },
  {
    path: 'clave-web',
    loadChildren: () => import('./modules/clave-web/clave-web.module').then(m => m.ClaveWebModule)
  },
  {
    path: 'exit',
    component: ExitComponent
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
