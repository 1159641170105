import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/http/auth.service';

@Component({
  selector: 'app-confirmacion',
  templateUrl: './confirmacion.component.html',
  styleUrls: ['./confirmacion.component.css']
})
export class ConfirmacionComponent implements OnInit, OnDestroy {
  @Output() changeStep: EventEmitter<any> = new EventEmitter();
  @Input() stepChange: string;
  @Input() step: string;
  module = 'confirm';
  intervalo;

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.intervalo = setInterval(() => {
      this.authService.logout();
    }, 20000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalo);
  }

  opcion(action: string) {
    if (action === 'si') {
      localStorage.setItem('back', '');
      if (this.step === '') {
        this.changeStep.emit(this.stepChange);
      } else {
        this.router.navigate([this.step]);
      }
    } else {
      this.module = 'exit';
    }
  }
}
