import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-opcion-entrega',
  templateUrl: './opcion-entrega.component.html',
  styleUrls: ['./opcion-entrega.component.css']
})
export class OpcionEntregaComponent implements OnInit {
  @Output() changeStep = new EventEmitter<any>();
  @Input() step: string;
  @Input() back: string;
  @Input() module: string;
  @Input() option: number;
  @Input() source: string;
  print = '';
  view = 'opc-entrega';

  constructor() { }

  ngOnInit() {
  }

  menu(action: string) {
    this.view = 'email';
    if (action === 'email') {
      this.print = '';
    } else {
      this.print = 'print';
    }
  }

  volver(step: any) {
    this.changeStep.emit(step);
  }
}
