import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ReporteriaService } from 'src/app/core/services/http/reporteria/reporteria.service';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.css']
})
export class EmailSentComponent implements OnInit {
  @Output() changeStep: EventEmitter<any> = new EventEmitter();
  @Input() action: string;
  @Input() email: string;
  module = true;
  view = '';

  constructor(
    private reporteriaService: ReporteriaService
  ) { }

  ngOnInit() {
    this.validaView();
    setTimeout(() => {
      this.view = 'suscribirse';
    }, 10000);
  }

  validaView() {
    if (localStorage.getItem('comunicaciones')) {
      this.module = false;
    } else {
      if (this.action === 'print') {
        this.view = 'print';
      } else {
        this.view = 'email';
      }
    }
  }

  opcion(action: string) {
    if (action === 'si') {
      this.reporteUsabilidad(
        `Agregar correo para futuras comunicaciones`,
        '',
        'Si',
        this.email,
        true
      );
      this.module = false;
    } else {
      this.reporteUsabilidad(
        `Agregar correo para futuras comunicaciones`,
        '',
        'No',
        this.email,
        true
      );
      this.module = false;
    }
    localStorage.setItem('comunicaciones', 'true');
  }

  reporteUsabilidad(modulo: string, recurso: string, respuesta: string, correo: string, status: boolean) {
    const rut = localStorage.getItem('afpRut');

    this.reporteriaService.saveLog(
      modulo,
      recurso,
      respuesta,
      'Front-End',
      correo,
      status,
      rut
    );
  }

}
