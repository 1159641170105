import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/http/auth.service';

@Component({
  selector: 'app-exit',
  templateUrl: './exit.component.html',
  styleUrls: ['./exit.component.css']
})
export class ExitComponent implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit() {
    setTimeout(() => {
      localStorage.clear();
      this.authService.logout();
    }, 5000);
  }
}
